import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';
import Home from '@/views/Home.vue';
import Incident from '@/views/Incident.vue';
import Deployment from '@/views/Deployment.vue';
import Login from '@/views/Login.vue';
import Token from '@/views/Token.vue';
import Flatrate from '@/views/Flatrate.vue';
import Capacity from '@/views/Capacity.vue';
import IncidentsHno from '@/views/IncidentsHno.vue';
import SuiviHno from '@/views/SuiviHno.vue';
import Timeline from '@/views/Timeline.vue';
import Semainier from '@/views/Semainier.vue';
import Devis from '@/views/Devis.vue';

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'Home',
    //   redirect: '/overview',
    component: Home,
  },
  {
    path: '/Login',
    name: 'Login',
    component: Login,
  },
  {
    path: '/Token',
    name: 'token',
    component: Token,
  },
  {
    path: '/incident',
    name: 'Incident',
    component: Incident,
  },

  {
    path: '/deployment',
    name: 'Déploiement',
    component: Deployment,
  },
  {
    path: '/overview',
    name: 'Overview',
    component: () => import('../views/Overview.vue'),
  },
  {
    path: '/travaux',
    name: 'Travaux',
    component: () => import('../views/Travaux.vue'),
  },
  {
    path: '/setting',
    name: 'Setting',
    component: () => import('../views/Setting.vue'),
  },
  {
    path: '/client',
    name: 'Client',
    component: () => import('../views/Client.vue'),
  },
  {
    path: '/devis',
    name: 'Devis',
    component: Devis,
  },
  {
    path: '/facture',
    name: 'Facture',
    component: () => import(/* webpackChunkName: "about" */ '../views/Facture.vue'),
  },
  {
    path: '/flat-rate',
    name: 'Forfait',
    component: Flatrate,
  },
  {
    path: '/incidents-hno',
    name: 'incidentsHno',
    component: IncidentsHno,
  },
  {
    path: '/suivi-hno',
    name: 'suiviHno',
    component: SuiviHno,
  },
  {
    path: '/semainier',
    name: 'semainier',
    component: Semainier,
  },
  {
    path: '/timeline',
    name: 'timeline',
    component: Timeline,
  },
  {
    path: '/table',
    name: 'Table',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Table.vue'),
  },
  {
    path: '/search',
    name: 'Search',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Search.vue'),
  },
  {
    path: '/capacity',
    name: 'Capacity',
    component: Capacity,
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

export default router;
