<template>
  <div>
    <div v-if="customerId" class="row m-4">
      <div class="col-md-8">
        <Filters
          v-on:refresh="getItem()"
          v-on:skaler="$event => (this.selectedSkaler = $event)"
          :calendarFilters="calendarFilterFields"
          :skalersFilter="true"
          :myTicketsFilter="false"
          style="padding: 0"
        >
        </Filters>
        <SuiviHno v-if="suiviHno" title="Skaler HNO" subTitle="" :options="suiviHno" />
      </div>

      <div class="col-md-4" style="max-height: 542px">
        <b-card title="Récapitulatif" style="height: 100%">
          <div class="row mb-2">
            <div class="col-6">
              <b-form-select v-model="currentMonth" :options="getMonths()"></b-form-select>
            </div>

            <div class="col-6">
              <b-form-select v-model="currentYear" :options="[currentYear - 1, currentYear, currentYear + 1]">
              </b-form-select>
            </div>
          </div>

          <div class="month-container mt-1 p-3">
            <b-table striped hover :fields="column" :items="filteredHnoByMonth()"></b-table>
          </div>
        </b-card>
      </div>

      <div class="mt-2 col-md-12">
        <b-card :title="'Heures non ouvrées ' + currentYear" style="height: 100%" class="col-md-12">
          <LineGraph :series="series" :currentYear="currentYear"></LineGraph>
        </b-card>
      </div>
    </div>
  </div>
</template>

<script>
import IncidentService from '../services/incident';
import EndPoint from '../services/endpoint';
import LineGraph from '@/components/LineGraph.vue';
import SuiviHno from '@/components/SuiviHno.vue';
import Filters from '@/components/Filters.vue';
import { ObjectiveKind } from '@/types/gitlab';
import _ from 'lodash';

export default {
  components: {
    Filters,
    LineGraph,
    SuiviHno,
  },
  data() {
    return {
      customerId: 'admin',
      squadId: 'admin',
      suiviHno: false,
      column: [
        {
          key: 'o_assignee',
          label: 'Skaler',
        },
        {
          key: 'count',
          label: 'Nb HNO',
        },
      ],
      currentYear: new Date().getFullYear(),
      currentMonth: new Date().getMonth(),
      groupObjective: [],
      formatedHno: {
        name: 'Heure non ouvrée',
        data: [],
      },
      calendarFilterFields: [
        {
          content: 'Date de création ',
          value: 'created',
        },
        {
          content: 'Date de résolution ',
          value: 'resolve',
        },
      ],
      selectedSkaler: null,
      series: [],
    };
  },
  mounted() {
    if (localStorage.customerId) {
      this.customerId = localStorage.customerId;
    } else {
      this.$router.push('/Login');
    }

    this.$root.$on('currentClient', data => {
      if (this.$route.path != '/suivi-hno') return;

      this.refreshData(data);
    });

    this.$root.$on('currentSquad', squadId => {
      if (this.$route.path != '/suivi-hno') return;

      this.squadId = squadId;
      this.getItem();
    });

    this.getItem();
  },
  watch: {
    currentYear() {
      //   this.currentHno = [];
    },
    selectedSkaler() {
      this.getItem();
    },
  },
  methods: {
    async refreshData(data) {
      if (data.customerId) this.customerId = data.customerId;
      this.getItem();
    },
    async getItem() {
      await IncidentService.getIncidentByYear(
        EndPoint.get(this.customerId) + EndPoint.hnoParams(null, true, true),
        this.currentYear,
      ).then(response => {
        if (this.selectedSkaler) {
          response = response.filter(o => o.assignee === this.selectedSkaler);
        }
        response = response.filter(e => {
          return e.kind == ObjectiveKind.UNRESOLVED_INCIDENT || e.kind == ObjectiveKind.PLANNED_CHANGE;
        });

        this.suiviHno = response;
      });
      await IncidentService.getGroupIncidentByYear(
        EndPoint.group(this.customerId) + EndPoint.hnoParams(null, true, false),
        this.currentYear,
      ).then(response => {
        this.groupObjective = response;
        this.formatHno(response);
      });
    },
    formatHno(data) {
      const category = _.uniq(this.extractCategories(data, 'customer_name'));
      this.series = category.map(value => {
        return this.extractSeries(data, value);
      });
    },
    extractSeries(dataList, category) {
      const response = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
      let month = 1;
      dataList.forEach(element => {
        if (element.customer_name == category) {
          month = element.month - 1;
          response[month] += parseInt(element.count);
        }
      });
      return {
        name: category,
        data: response,
      };
    },
    extractCategories(dataList, categoryField) {
      return dataList.map(data => {
        return data[categoryField];
      });
    },
    getMonths() {
      return [
        { text: 'Jan', value: 0 },
        { text: 'Feb', value: 1 },
        { text: 'Mar', value: 2 },
        { text: 'Apr', value: 3 },
        { text: 'May', value: 4 },
        { text: 'Jun', value: 5 },
        { text: 'Jul', value: 6 },
        { text: 'Aug', value: 7 },
        { text: 'Sep', value: 8 },
        { text: 'Oct', value: 9 },
        { text: 'Nov', value: 10 },
        { text: 'Dec', value: 11 },
      ];
    },
    filteredHnoByMonth() {
      return this.groupObjective.filter(e => e.month == this.currentMonth + 1);
    },
  },
};
</script>

<style scoped lang="scss">
.month-container {
  overflow-y: auto;
  height: 420px;
}
</style>
