module.exports = {
  // API
  apiPreprodUrl: 'https://api.preprod.mysk5.com/',
  apiProdUrl: 'https://api.mysk5.com/',
  // BEEKEEPER
  beekeperPreprodUrl: 'https://beekeeper.preprod.mysk5.com/',
  beekeperProdUrl: 'https://beekeeper.mysk5.com/',
  // LOCAL
  apiLocalUrl: 'http://localhost:3000/',
  getUrl() {
    if (process.env.NODE_ENV == 'development') {
      return this.apiLocalUrl;
    } else {
      return this.apiPreprodUrl;
    }
  },
  getBeekeeperUrl() {
    if (process.env.NODE_ENV == 'development') {
      return this.beekeperPreprodUrl;
    } else {
      return this.beekeperPreprodUrl;
    }
  },
};
